@font-face {font-family: regInter; src: url(./utils/font/Inter-Regular.ttf); }
@font-face {font-family: medInter; src: url(./utils/font/Inter-Medium.ttf); }
@font-face {font-family: semInter; src: url(./utils/font/Inter-SemiBold.ttf); }
@font-face {font-family: boldInter; src: url(./utils/font/Inter-Bold.ttf); }
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(./utils/font/MaterialIconsOutlined-Regular.otf); /* For IE6-8 */
}  
@font-face {
    font-family: 'Material Fills';
    font-style: normal;
    font-weight: 400;
    src: url(./utils/font/MaterialIcons-Regular.ttf); /* For IE6-8 */
} 

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 20px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    vertical-align: middle;
  
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
  
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
  
    /* Support for IE. */
    font-feature-settings: 'liga';
}

.material-fills {
    font-family: 'Material Fills';
    font-weight: normal;
    font-style: normal;
    font-size: 20px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    vertical-align: middle;
  
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
  
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
  
    /* Support for IE. */
    font-feature-settings: 'liga';
  }

body {
    margin: 0; background: #DFDFDF;
    font-family: regInter;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*:focus { outline: none; }

input[type=text], input[type=tel], input[type=number], input[type=date], select, button {height: 44px;border-radius: 6px;}
input[type=radio] {width:18px;height:18px;padding:2px;margin-top:3px}
button:hover, button:active {color:inherit;}


hr{margin-top: 5px;}
button:hover{color: inherit;}
.color-danger{color: #f05122;}
/* a{color: inherit !important;} */

.topbar {background-color: #4d2113; color: white; padding: 10px 20px;}
.topbar img{height: 30px; }
.topbar a, .topbar a:hover{color: #f05122;}
.topbar .companyName{font-weight: 800;}

.btnCon button{
    background-color: rgba(77, 33, 19, 0.1); color: #f05122; height: 32px; font-weight: 700;
    border-bottom-left-radius: 0; border-bottom-right-radius: 0;
    border-top-left-radius: 8px; border-top-right-radius: 8px; margin-right: 6px;
    margin-left: 6px; padding-left: 15px; padding-right: 15px;
}
.btnCon button.active{
    background-color: #f05122; color: #fff; 
}
.buttom-line{height: 8px; background-color: #f05122;}
.loginCon{height: 60vh;}
.loginCon .login{
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1); 
    border-radius: 12px;width:400px;background-color: white;
    border: 1px solid #4d2113;
    overflow: hidden;
}
.loginCon .login .title{
    padding:10px 20px; font-weight: 700;
    background-color: rgba(77, 33, 19, 0.1);
    color: #4d2113;
}

.loginCon .login .controls{
    padding:10px 20px; font-weight: 500;
}

.loginCon .login .controls input[type='email'], .loginCon .login .controls input[type='password']{
    border-radius: 8px;
}

.loginCon .btnLogin{
    border: 1px solid #4d2113; background-color: #4d2113; 
    height: 32px; padding-left: 30px; padding-right: 30px;
    color: white; font-weight: 500;
}

.adminBtnCon button {
    background-color: #4d2113; color: white;
    width: 100%;
}

.adminBtnCon .btn-group {width: 100%;}

.adminBtnCon button:hover, .adminBtnCon button.active {
    background-color: gainsboro; color: #4d2113; font-weight: 700;
}

.adminHead{
    font-weight: bold;
    font-size: 18px;
    columns: #4d2113;
    border-bottom: 1px solid #4d2113;
    padding-bottom: 8px;
}
.adminHead select{max-width: 200px;text-transform: capitalize;}
.adminHead button{white-space: nowrap; background-color: #4d2113; color: white; height: 32px;} 
.adminBody{
    padding: 20px;
}

.adminBody label{font-weight: 600;}
input[type='text'], 
input[type='number'], 
input[type='date'],
input[type='tel'],
input[type='email'],
select
{height: 32px;}

.table{
    padding: .25rem .75rem;
}

